<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    header="Deactivate Email Communicator?"
  >
    <div class="connect-instructions">
      <p class="intro-text">
        Are you sure you want to deactivate your email communicator? You will no longer receive emails with your analysis results.
      </p>
    </div>
    <div class="dialog-buttons">
      <button
        class="btn-secondary"
        @click="closeDialog"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        @click="disconnect"
      >
        Deactivate
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';

// Open/close

const visible = ref(false);
const store = useStore();

let id = ref('');

// Open/close

const openDialog = (communicator) => {
  id = communicator.id;
  visible.value = true;
};
const closeDialog = () => {
  visible.value = false;
};

const disconnect = async () => {
  const values = {
    communicator_id: id,
    name: 'Email',
    status: false,
  };

  await store.dispatch('Communicators/toggleCommunicator', values);
  visible.value = false;
};

defineExpose({ openDialog, closeDialog });

</script>

<style scoped>
  .connect-instructions {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }

  .intro-text, .outro-text {
    margin-bottom: 25px;
  }

  .steps-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 25px;
  }

  .steps-list li {
    margin-bottom: 15px;
    padding-left: 10px;
    position: relative;
  }

  .steps-list li span {
    font-weight: bold;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .cta-link, .connect-button {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
  }

  .cta-link:hover, .connect-button:hover {
    color: #0056b3;
  }

  .connect-button {
    display: inline-block;
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;
    transition: background-color 0.3s;
  }

  .connect-button:hover {
    background-color: #0056b3;
  }
</style>
