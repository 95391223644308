<template>
  <div class="card">
    <h2 class="card-header">
      Communicators
    </h2>
    <div class="flex card-body">
      <div class="grid-container">
        <div
          v-for="communicator in currentPage"
          :key="communicator.id"
          class="grid-card"
        >
          <div class="grid-card-header-integration">
            <img
              class="grid-card-image"
              :src="getCommunicatorImage(communicator.name)"
              :alt="communicator.name"
            >
            <h3 class="grid-card-title grid-card-centered">
              {{ communicator.name }}
            </h3>
          </div>
          <p class="grid-card-description grid-card-centered">
            {{ communicator.description }}
          </p>
          <div class="grid-card-button-wrapper">
            <button
              v-if="!communicator.status"
              class="btn-primary"
              @click="connect(communicator)"
            >
              Connect
            </button>
            <button
              v-if="communicator.status"
              v-element-hover="communicator.onHover"
              :class="communicator.hover ? '' : 'btn-secondary'"
              @click="disconnect(communicator)"
            >
              {{ communicator.hover ? 'Disconnect' : 'Connected' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ConnectEmailCommunicatorDialog ref="newEmailCommunicatorDialog" />
    <DisconnectEmailCommunicatorDialog ref="deleteEmailCommunicatorDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, onMounted } from 'vue';
import { vElementHover } from '@vueuse/components';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import { COMMUNICATOR_MODEL_FIELDS } from '#features/communicators/lib/models/CommunicatorModel';
import ConnectEmailCommunicatorDialog from '#features/communicators/ui/dialogs/ConnectEmailCommunicatorDialog';
import DisconnectEmailCommunicatorDialog from '#features/communicators/ui/dialogs/DisconnectEmailCommunicatorDialog';

// Setup
/* eslint-disable */
const store = useStore();
const currentPage = computed(() => {
  let communicators = store.state.Communicators.currentPage;
  communicators.forEach(communicator => {
    communicator.onHover = ( state ) => {
      communicator.hover = state;
    }
    communicator.hover = false;
  });
  return communicators;
});

const totalPages = computed(() => 1);
const communicatorFields = computed(() => omit(COMMUNICATOR_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('Communicators/getList', values),
  'getList',
  getCurrentInstance(),
);

const nameToImageMap = {
  'Email': require('#ui/assets/communicator_logos/email.svg'),
};

const getCommunicatorImage = (name) => {
  if (name in nameToImageMap){
    return nameToImageMap[name];
  }
  return require('#ui/assets/integration_logos/default.svg');
}

const connected = ref(false);

const newEmailCommunicatorDialog = ref(null);
const openEmailDialog = (communicator) => {
  newEmailCommunicatorDialog.value.openDialog(communicator);
};

const connectDialogToNameMap = {
  'Email': openEmailDialog,
}

const deleteEmailCommunicatorDialog = ref(null);
const openEmailDeleteDialog = (communicator) => {
  deleteEmailCommunicatorDialog.value.openDialog(communicator);
};

const disconnectDialogToNameMap = {
  'Email': openEmailDeleteDialog,
}

const connect = (communicator) => {
  const name = communicator.name;
  if (name in connectDialogToNameMap) {
    connectDialogToNameMap[name](communicator);
  }
  return;
}

const disconnect = (communicator) => {
  const name = communicator.name;
  if (name in disconnectDialogToNameMap) {
    disconnectDialogToNameMap[name](communicator);
  }
  return;
}

const getPage = async () => {
  try {
    await getList({
      page: 1,
      per: 20,
      search: '',
    });
  }
  catch (error) {
    console.log(error);
    console.log('error');
  }
};

onMounted(async () => {
  await getPage();
});

</script>
